<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper details-form">
      <!-- Inner wrapper handles global padding -->
      <!-- main headings (first on page) structured like this with global styles -->
      <div class="heading-section">
        <h1 class="heading3">Newsletter Signup</h1>
      </div>
      <p style="text-align: center">Want to stay up to date? Register here.</p>

      <form class="pax_entry" @submit.prevent="submitForm">
        <label for="name" class="label">Name</label>
        <input
          id="name"
          class="input"
          type="text"
          v-model="name"
          placeholder="Name (Optional)"
        />

        <label for="email" class="label">Email</label>
        <input
          type="email"
          class="input"
          v-model="email"
          placeholder="Email"
          required
        />

        <p v-if="error" class="invalid-feedback">{{ error }}</p>

        <button
          type="submit"
          class="button continue save"
          :class="{ disabled: loading }"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from "@/helpers/axios.js";
import account from "@/helpers/account.js";

export default {
  data() {
    return {
      name: "",
      email: "",
      loading: false,
      error: "",
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.error = "";

      // Basic validation
      if (!account.validEmail(this.email)) {
        this.error = "Invalid email address";
        this.loading = false;
        return;
      }

      try {
        const response = await apiClient.post("/newsletter_signup", {
          name: this.name,
          email: this.email,
        });

        // Handle the response
        if (response.data.success) {
          // Success
          this.$router.push({ name: "Newsletter Signup Success" });
        } else {
          // Error
          this.error = response.data.error;
        }
        this.loading = false;
      } catch (error) {
        // Handle the error
        this.error = error.response
          ? error.response.data
          : "An error occurred. Please check that your email is valid.";
        this.loading = false;
      }
    },
  },
  created() {
    this.$emit("update:topLayoutSettings", {
      left: {
        type: "back",
        route: this.$route.query.redirect
          ? this.$route.query.redirect
          : { name: "home" },
      },
      right: {
        type: "none",
      },
    });
  },
};
</script>

<style scoped>
.details-form {
  padding-bottom: 10px;
}
</style>
